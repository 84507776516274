@media print {
	@page{
		size: A4;
	}
}
ul{
	padding: 0;
	list-style: none;
}
body{
	font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
	margin: 0;
}
.container{
	padding: 20px 40px;
}
.inv-header{
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
}
.inv-header :nth-child(2){
	flex-basis: 40%;
}
.inv-title{
	padding: 10px;
	border: 1px solid silver;
	text-align: center;
	margin-bottom: 20px;
}
.no-margin{
	margin: 0;
}
.inv-logo{
	width: 150px;
}
.inv-header h2{
	font-size: 20px;
	margin: 1rem 0 0 0;
}
.inv-header ul li{
	font-size: 15px;
	padding: 3px 0;
}

/* table in head */
.inv-header table{
	width: 100%;
	border-collapse: collapse;
}
.inv-header table th, .inv-header table td, .inv-header table{
	border: 1px solid silver;
}
.inv-header table th, .inv-header table td{
	text-align: right;
	padding: 4px;
}

/* Body */
.inv-body{
	margin-bottom: 20px;
}
.inv-body table{
	width: 100%;
	border: 1px solid silver;
	border-collapse: collapse;
}
.inv-body table th, .inv-body table td{
	padding: 5px;
	border: 1px solid silver;
}
.inv-body table td h5, .inv-body table td p{
	margin: 0 5px 0 0;
}
/* Footer */
.inv-footer{
	clear: both;
	overflow: auto;
}
.inv-footer table{
	width: 40%;
	float: right;
	border: 1px solid silver;
	border-collapse: collapse;
}
.inv-footer table th, .inv-footer table td{
	padding: 4px;
	text-align: right;
	border: 1px solid silver;
}